<template>
  <div class="main-layout__content">
    <div class="content">
      <h1 class="page-title" v-t="'not_found'"></h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>