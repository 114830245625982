import { render, staticRenderFns } from "./PlayerGames.vue?vue&type=template&id=074fcebe&scoped=true&"
import script from "./PlayerGames.vue?vue&type=script&lang=js&"
export * from "./PlayerGames.vue?vue&type=script&lang=js&"
import style0 from "./PlayerGames.vue?vue&type=style&index=0&id=074fcebe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "074fcebe",
  null
  
)

export default component.exports