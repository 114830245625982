export default new Set([
  "assault1",
  "assault2",
  "assault3",
  "blink1",
  "blink2",
  "blink3",
  "cyclone1",
  "cyclone2",
  "cyclone3",
  "force1",
  "force2",
  "force3",
  "fountain1",
  "fountain2",
  "fountain3",
  "greaves1",
  "greaves2",
  "greaves3",
  "levelup1",
  "levelup2",
  "levelup3",
  "mek1",
  "mek2",
  "mek3",
  "mjollnir1",
  "mjollnir2",
  "mjollnir3",
  "phase1",
  "phase2",
  "phase3",
  "pipe1",
  "pipe2",
  "pipe3",
  "radiance1",
  "radiance2",
  "radiance3",
  "shiva1",
  "shiva2",
  "shiva3",
  "shivaaura1",
  "shivaaura2",
  "shivaaura3",
  "vlads1",
  "vlads2",
  "vlads3",
  "deny1",
  "deny2",
  "deny3",
  "deny4",
  "deny5",
  "choppy_the_log_surfer",
  "cluckles_the_brave",
  "doomling",
  "huntling",
  "itsy",
  "krobeling",
  "llama_the_llama",
  "mighty_boar",
  "shroom_hat",
  "turbo_the_turtle",
  "venoling",
  "greevil",
  "janjou",
  "landshark",
  "parasight",
  "pudgling",
  "shagbark_the_plush",
  "tory",
  "butch",
  "drodo",
  "tenderfoot",
  "scotty",
  "tory_the_sky_guardian",
  "angelic_recruit",
  "demon_intern",
  "nian_the_cuddle_beast",
  "baby_roshan",
  "icefrog",
  "carty",
  "spring_haze",
  "butterfly_romp",
  "felicitys_blessing",
  "acid_mist",
  "orbital_decay",
  "searing_essence",
  "winds_of_winter",
  "spirit_of_ember",
  "touch_of_midas",
  "silver_streak",
  "bleak_hallucination",
  "crystal_rift",
  "frostivus_frost",
  "desert_storm",
  "trail_of_the_lotus_blossom",
  "oceans_call",
  "champions_aura_2014",
  "ethereal_flame",
  "star_blaze",
  "trail_of_burning_doom",
  "huskie",
  "captain_bamboo",
  "brewling",
  "steeljaw",
  "coral",
  "brightjoy",
  "i_love_you",
  "golden_skin",
  "honey_heist",
  "honey_roshan",
]);
